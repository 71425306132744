<template>
  <div>
    <div class="w-75 d-flex flex-column justify-content-start inter-500-14">
      <div class="row mt-3">
        <div class="col-sm-12 col-lg-6 fs-4 fw-bold text-start">
          Marketplace Orders - Confirmed
        </div>
        <k-button
          variant="info"
          class="col-sm-12 col-lg-4"
          @click="download(reportsEndpoint.marketPlaceOrdersMetrics, { isDownloadingOrderMetricsM: true })"
        >
          <loader-icon v-if="isDownloadingOrderMetricsM" />
          <div v-else>
            <kingpin-icon
              icon="DownloadCloudIcon"
              size="20"
            />
            <span class="ps-2"> Order Metrics - Marketplace </span>
          </div>
        </k-button>
      </div>
      <div class="row mt-3">
        <div class="col-sm-12 col-lg-6 fs-4 fw-bold text-start">
          Direct Orders
        </div>
        <k-button
          variant="info"
          class="col-sm-12 col-lg-4"
          @click="download(reportsEndpoint.directOrdersMetrics, { isDownloadingOrderMetricsD: true })"
        >
          <loader-icon v-if="isDownloadingOrderMetricsD" />
          <div v-else>
            <kingpin-icon
              icon="DownloadCloudIcon"
              size="20"
            />
            <span class="ps-2"> Order Metrics - Direct </span>
          </div>
        </k-button>
      </div>
      <div class="row mt-3">
        <div class="col-sm-12 col-lg-6 fs-4 fw-bold text-start">
          Marketplace Orders - All
        </div>
        <k-button
          variant="info"
          class="col-sm-12 col-lg-4"
          @click="download(reportsEndpoint.marketPlaceOrdersAllMetrics, { isDownloadingOrderMetricsMA: true })"
        >
          <loader-icon v-if="isDownloadingOrderMetricsMA" />
          <div v-else>
            <kingpin-icon
              icon="DownloadCloudIcon"
              size="20"
            />
            <span class="ps-2"> Order Metrics All - Marketplace </span>
          </div>
        </k-button>
      </div>
      <div class="row mt-3">
        <div class="col-sm-12 col-lg-6 fs-4 fw-bold text-start">
          Investor Report
        </div>
        <k-button
          variant="info"
          class="col-sm-12 col-lg-4"
          @click="download(reportsEndpoint.investorMetrics, { isDownloadingInvestorMetrics: true })"
        >
          <loader-icon v-if="isDownloadingInvestorMetrics" />
          <div v-else>
            <kingpin-icon
              icon="DownloadCloudIcon"
              size="20"
            />
            <span class="ps-2"> Download Investor Metrics </span>
          </div>
        </k-button>
      </div>
    </div>
  </div>
</template>

<script>
import { KButton, KingpinIcon } from '@kingpin-global/kingpin-ui'
import { kpEndpoint, kpRequest } from '@/axios'
import { downloadCSV } from '@/common-utils'
import { apiToastErrorV2, apiToastSuccessV2 } from '@/@core/utils/toast'
import { LoaderIcon } from 'vue-feather-icons'

const reportsEndpoint = kpEndpoint.reports

export default {
  name: 'ReportOrders',
  components: {
    KButton,
    KingpinIcon,
    LoaderIcon,
  },
  data() {
    return {
      reportsEndpoint,
      isDownloadingOrderMetricsM: false,
      isDownloadingOrderMetricsD: false,
      isDownloadingOrderMetricsMA: false,
      isDownloadingInvestorMetrics: false,
    }
  },
  methods: {
    setLoader(loadingObject) {
      Object.keys(loadingObject).forEach(key => {
        this[key] = loadingObject[key]
      })
    },
    resetLoader(loadingObject) {
      Object.keys(loadingObject).forEach(key => {
        this[key] = false
      })
    },
    async download(endpointObject = {}, loadingObject = {}) {
      this.setLoader(loadingObject)
      try {
        const csvResponse = await kpRequest({ ...endpointObject })
        downloadCSV({ csv: csvResponse.data })
        apiToastSuccessV2('Downloaded successfully')
        this.resetLoader(loadingObject)
      } catch (error) {
        apiToastErrorV2(error)
        this.resetLoader(loadingObject)
      }
    },
  },
}
</script>
