<template>
  <div>
    <k-header-card class="pb-0">
      <div class="d-flex align-items-center">
        <div
          class="w-100 d-flex justify-content-between header-with-save-button"
        >
          <div class="w-50 d-flex flex-column align-items-start">
            <div class="poppins-700-24 header-text text-secondary">
              Reports
            </div>
          </div>
        </div>
      </div>
    </k-header-card>

    <div class="kp-body-container">
      <report-orders />
    </div>
  </div>
</template>

<script>
import KHeaderCard from '@/@core/components/KHeaderCard.vue'
import ReportOrders from './ReportOrders.vue'

export default {
  name: 'ShipmentManagement',
  components: {
    ReportOrders,
    KHeaderCard,
  },
}
</script>

<style lang="scss">
@import "@/@core/scss/vue/pages/kp-management.scss";
</style>
